import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useStaticQuery, graphql } from "gatsby";
import Slider from "react-slick"
import Carousel, { consts } from "react-elastic-carousel";
import atob from "atob";

import Layout from "../components/layout";
import SEO from "../components/seo";

import "../fonts/fonts.css";
import "./home.scss";

import Button from "../components/button/button";
import SectionHeading from "../components/section-heading/section-heading";
import SelectedWork from "../components/selected-work/selected-work";
import ContactSection from "../components/contact-section/contact-section";
import CappedWidth from "../components/capped-width/capped-width";

//Import images from images.js here:
import { BannerImage } from "../components/image";
import arrowDown from "../images/arrow-down.svg";
import arrowLeft from "../images/arrow-left.svg";
import arrowRight from "../images/arrow-right.svg";

// Strip "data:image/svg+xml;base64," to get encoded SVG
const arrowLeftEncoded = arrowLeft.substring(26);
const arrowRightEncoded = arrowRight.substring(26);
let arrowLeftDecoded, arrowRightDecoded;

try {
  // development
  arrowLeftDecoded = window.atob(arrowLeftEncoded);
  arrowRightDecoded = window.atob(arrowRightEncoded);
} catch (e) {
  // production
  arrowLeftDecoded = atob(arrowLeftEncoded);
  arrowRightDecoded = atob(arrowRightEncoded);
}

function ElasticCarouselArrow ({ type, onClick, isEdge }) {
  return (
    <div
      className={`carousel-arrow ${isEdge ? "disabled" : ""} purple`}
      onClick={onClick}
      dangerouslySetInnerHTML={{
        __html: type === consts.PREV ? arrowLeftDecoded : arrowRightDecoded
      }}
    />
  );
};

function SlickCarouselArrow ({ onClick, left }) {
  return (
    <div
      className={`carousel-arrow gold`}
      onClick={onClick}
      dangerouslySetInnerHTML={{
        __html: left ? arrowLeftDecoded : arrowRightDecoded
      }}
    />
  );
};

const IndexPage = () => {
  const {
    allBooksCsv: { nodes: selectedWorks },
    allTestimonialsCsv: { nodes: testimonials }
  } = useStaticQuery(graphql`
    {
      allBooksCsv(filter: { Selected_Work: { eq: "Yes" } }) {
        nodes {
          id
          Title
          Cover_Image_URL
          Author_Name
          Author_Image_URL
          Series
          Publication_Date
          Publisher
          Genres
        }
      }
      allTestimonialsCsv {
        nodes {
          id
          Name
          Role
          Comment
        }
      }
    }
  `);

  const testimonialSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SlickCarouselArrow left />,
    nextArrow: <SlickCarouselArrow />
    // adaptiveHeight: true
  }

  // shim to trigger good carousel sizes
  useEffect(() => {
    // we have to wait for css
    setTimeout(() => {
      // scroll to given url hash
      if (window.location.hash) {
        const el = document.querySelector(window.location.hash);
        if (el) {
          el.scrollIntoView({
            behaviour: "smooth",
            block: "start"
          });
        }
      }
    }, 300);
    // the [] means it will only run on first mount
  }, []);

  return (
    <Layout>
      <SEO title="Home" />
      {/* Homepage Banner */}
      <div className="homepage-banner">
        <div className="homepage-banner-cover">
          <BannerImage />
        </div>
        <div className="homepage-banner-content">
          <div className="banner-text-wrapper">
            <h3 className="banner-surtitle">
              Become a better writer by joining OWW
            </h3>
            <h1 className="banner-title">Online Writing Workshop</h1>
            <h2 className="banner-subtitle">
              for Science Fiction, Fantasy and Horror
            </h2>
          </div>
          <div className="banner-buttons">
            <Button
              color="golden"
              fill
              text="Start Your Free Trial"
              path="/free-trial.shtml"
            />
            <Button color="golden" text="Learn More" path="/what-we-offer/" />
          </div>
        </div>
        <a href="/#why-join-oww">
          <img className="scroll-arrow" src={arrowDown} />
        </a>
      </div>

      {/* Why Join OWW */}
      <div className="section why-join-oww" id="why-join-oww">
        <CappedWidth>
          <SectionHeading>Why Join OWW?</SectionHeading>
          <p>
            Since 2000, OWW has been helping people become better writers. Our
            members have made over 1,500 sales to print and online publications,
            won many awards (including the Hugo), and appeared in Best Of
            anthologies. Dozens of members have sold novels to major publishers.
          </p>
          <div className="why-join-buttons">
            <Button
              color="purple"
              fill
              text="Start Your Free Trial"
              path="/free-trial.shtml"
            />
            <Button
              color="purple"
              text="Member Success Stories"
              path="/#member-success-stories"
            />
          </div>
        </CappedWidth>
      </div>
      <div className="spacer" />
      {/* Member Testimonials */}
      <div className="section last member-testimonials">
        <SectionHeading>Member Testimonials</SectionHeading>
        <h3>What OWW members say about their workshop experience</h3>
        <div className="testimonial-carousel-wrapper">
        <Slider {...testimonialSettings} className="testimonial-carousel">
            {testimonials.map(testimonial => (
              <div className="testimonial-slide" key={testimonial.id}>
                <div className="quote-wrapper">
                  <div className="quote-symbol">“</div>
                  <p className="quote-content">{testimonial.Comment}</p>
                </div>
                <div className="testimonials-name-and-title">
                  - <div className="testimonials-name">{testimonial.Name}</div>
                  {`, `}
                  {testimonial.Role}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      {/* Member Success Stories */}
      <div className="member-success-stories" id="member-success-stories">
        <div className="section last">
          <SectionHeading>Member Success Stories</SectionHeading>
          {/* Change or add selected books here: */}
          <Carousel
            className="selected-works-wrapper"
            pagination={false}
            breakPoints={[
              { itemsToShow: 1, itemsToScroll: 1 },
              { width: 600, itemsToShow: 2, itemsToScroll: 2 }
            ]}
            renderArrow={ElasticCarouselArrow}
          >
            {selectedWorks.map((selectedWork, index) => (
              <div key={selectedWork.id}>
                <SelectedWork dark={index % 2 === 1} work={selectedWork} />
              </div>
            ))}
          </Carousel>
          <Button
            path="/member-book-gallery/"
            color="purple"
            semiTransparentFill
            text="Member Book Gallery"
            className="member-book-gallery-button"
          />
        </div>
      </div>
      <div id="contact">
        <ContactSection />
      </div>
    </Layout>
  );
};

export default IndexPage;
