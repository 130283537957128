import React from "react"
import PropTypes from "prop-types"
import "./item-with-shadow.scss"

const ItemWithShadow = ({ children, dark }) => {
  return (
    <div className={`item-with-shadow ${dark ? "dark" : ""}`}>
      <div className="frame">{children}</div>
    </div>
  )
}

ItemWithShadow.propTypes = {
  dark: PropTypes.bool, // shade, light by default
}

ItemWithShadow.defaultProps = {
  dark: false,
}

export default ItemWithShadow
