import React from "react";
import PropTypes from "prop-types";
import "./selected-work.scss";
import ItemWithShadow from "../item-with-shadow/item-with-shadow";

const SelectedWork = ({
  work: {
    Title,
    Series,
    Author_Name,
    Publisher,
    Publication_Date,
    Author_Image_URL,
  },
  dark,
}) => {
  return (
    <ItemWithShadow dark={dark}>
      <div className="selected-work-wrapper">
        <div className="selected-work-title-wrapper">
          <div className="selected-work-Author_Name">{Author_Name}</div>
          <div className="selected-work-title">{Title}</div>
          <div className="selected-work-series">{Series}</div>
        </div>
        <div className="selected-work-publisher">{Publisher}</div>
        <div className="selected-work-publish-date">{Publication_Date}</div>

        <div className="selected-work-author-image-wrapper">
          <img
            className="selected-work-author-image"
            src={Author_Image_URL}
            alt="author image of selected work"
          />
        </div>
      </div>
    </ItemWithShadow>
  );
};

SelectedWork.propTypes = {
  work: PropTypes.shape({
    Title: PropTypes.string,
    Series: PropTypes.string,
    Author_Name: PropTypes.string,
    Publisher: PropTypes.string,
    Publication_Date: PropTypes.string,
    Author_Image_URL: PropTypes.string,
  }),
  dark: PropTypes.bool, // shade, light by default
};

SelectedWork.defaultProps = {
  dark: false,
};

export default SelectedWork;
